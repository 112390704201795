/**
 * 腾讯天御验证码APPID
 * appid: 2064770078
 */
export const CAPTCHA_APPID = '2064770078';
/**
 * 宜享花域名
 */
export const YXH_DOMAINS = [
    'www.hainanwangdai.com',
    'm.hainanwangdai.com',
    'www.yxh1.cn',
    'www.yxh2.cn',
    'www.yxh3.cn',
    'www.yxh4.cn',
    'fenqi.creditinlife.com',
    'yrxd.test.creditease.cn',
    'yrxdtest.creditease.cn',
];
export const SHENGANG_DOMAIN = process.env.VUE_APP_RUNTIME_ENV === 'prod' ? 'http://api.wowgreat.cn' : 'http://dev.wowgreat.cn';
/**
 * 业务状态码
 */
export var BizCode;
(function (BizCode) {
    /**
     * 成功
     * @type {String}
     */
    BizCode["SUCCESS"] = "0000";
    /**
     * 未授权
     * @type {String}
     */
    BizCode["UNAUTHORIZED"] = "0001";
    /**
     * 需要验证码
     */
    BizCode["NEED_CAPTCHA"] = "0003";
    /**
     * 身份证示例浮层
     */
    BizCode["ID_CARD_EXAMPLES"] = "5000";
    /**
     * 识别失败去下载APP
     */
    BizCode["DOWNLOAD_APP"] = "5001";
    /**
     * 资金源切换
     */
    BizCode["FUND_PROVIDER_CHANGE"] = "0923";
    /**
     * 售罄
     */
    BizCode["SELL_OUT"] = "9800";
})(BizCode || (BizCode = {}));
export var CheesesRightsLevels;
(function (CheesesRightsLevels) {
    /**
     * 权益A档-芝士
     * 价值 3800
     */
    CheesesRightsLevels["A"] = "VIP_MONTH_A";
    /**
     * 权益B档-芝士
     * 价值 4800
     */
    CheesesRightsLevels["B"] = "VIP_MONTH_B";
    /**
     * 权益C档-芝士
     * 价值 6370
     */
    CheesesRightsLevels["C"] = "VIP_MONTH_C";
})(CheesesRightsLevels || (CheesesRightsLevels = {}));
/**
 * 支付状态
 */
export var PaymentStatus;
(function (PaymentStatus) {
    /**
     * 未购
     */
    PaymentStatus[PaymentStatus["NOT_PURCHASED"] = 1] = "NOT_PURCHASED";
    /**
     * 购买成功
     */
    PaymentStatus[PaymentStatus["SUCCESS"] = 2] = "SUCCESS";
    /**
     * 购买失败
     */
    PaymentStatus[PaymentStatus["FAILED"] = 3] = "FAILED";
    /**
     *
     */
    PaymentStatus[PaymentStatus["PAYING"] = 4] = "PAYING";
})(PaymentStatus || (PaymentStatus = {}));
